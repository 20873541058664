<template>
  <div class="relative pt-14 h-screen"> <!-- 这里设置一个顶部内边距来防止内容被导航栏遮挡，并设置高度为屏幕高度 -->
    <div class="absolute inset-0">
      <img src="@/assets/City.jpg" alt="Example Image" class="w-full h-full object-cover opacity-50">
    </div>
    <div class="relative z-10 container mx-auto p-4">
      <div class="bg-white bg-opacity-75 shadow-lg rounded-lg p-6 text-center">
        <h1 class="text-4xl font-bold text-gray-800 mb-4">City of Leaf</h1>
      </div>
    </div>
    <CharacterSelection />
  </div>
</template>

<script>
import CharacterSelection from '../components/CharacterSelection.vue';
export default {
  name: 'HomePage',
  components: {
    CharacterSelection
    // SignIn,
    // SingOut
  }
}
</script>

<style scoped>
.opacity-50 {
  opacity: 0.5;
}
</style>