<template>
    <div class="bg-gray-100 flex flex-col items-center min-h-screen pt-24 pb-24">
        <header class="w-full flex justify-center">
            <nav class="bg-slate-300 w-full rounded-md max-w-lg corner-rounded shadow-md p-4 lg:px-8"
                aria-label="Global">
                <!-- 链接部分 -->
                <div class="flex flex-col items-center h-full justify-between gap-y-2">
                    <a v-for="(role, index) in roles" :key="index" @click.prevent="checkPassword(role.link)"
                        class="w-80 bg-white shadow-md rounded-lg px-6 py-4 text-lg font-semibold text-gray-700 hover:bg-slate-400 hover:text-white transition duration-200 transform hover:-translate-y-1 cursor-pointer">
                        {{ role.name }}
                    </a>
                </div>
            </nav>
        </header>
    </div>

    <PasswordCheck v-if="showPasswordCheck" :route="currentRoute" :correctPassword="passwords[currentRoute]"
        @close="showPasswordCheck = false" />
</template>

<script setup>
import { ref } from 'vue'
import PasswordCheck from './PasswordCheck.vue'

const roles = [
    { name: "SynthoChem Corporation", link: "/SynthoChemCorporation" },
    { name: "Resident", link: "/LeafResident" },
    { name: "Water Treatment Plant", link: "/WaterTreatmentPlant" },
    { name: "Environmental Agency", link: "/EnvironmentAgency" },
    { name: "Health Department", link: "/HealthDepartment" },
    { name: "Facilitator", link: "/Facilitator" }
];

const showPasswordCheck = ref(false)
const currentRoute = ref('')

const passwords = {
    '/SynthoChemCorporation': 'syntho',
    '/LeafResident': 'leaf',
    '/WaterTreatmentPlant': 'water',
    '/EnvironmentAgency': 'environment',
    '/HealthDepartment': 'health',
    '/Facilitator': 'facilitator'
}

const checkPassword = (route) => {
    currentRoute.value = route
    showPasswordCheck.value = true
}
</script>

<style scoped>
/* Scoped styles are not needed since everything is handled by Tailwind CSS */
</style>