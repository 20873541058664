<template>
  <header class="bg-sky-700 fixed top-0 left-0 w-full z-50">
    <nav class="flex justify-center items-center p-3 lg:px-8 w-full" aria-label="Global">
      <!-- 移动端菜单按钮 -->
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
        </button>
      </div>
      <!-- 链接部分 -->
      <div class="hidden lg:flex flex-grow items-center justify-center gap-x-12">
        <router-link to="/" class="text-sm font-semibold leading-6 text-gray-200">HomePage</router-link>
        <router-link to="/about" class="text-sm font-semibold leading-6 text-gray-200">About</router-link>
        <template v-if="isDevelopment">
          <router-link to="/SynthoChemCorporation" class="text-sm font-semibold leading-6 text-gray-200">SynthoChem
            Corporation</router-link>
          <router-link to="/LeafResident" class="text-sm font-semibold leading-6 text-gray-200">Resident in the City of
            Leaf</router-link>
          <router-link to="/WaterTreatmentPlant" class="text-sm font-semibold leading-6 text-gray-200">Leaf Drinking
            Water Treatment Plant</router-link>
          <router-link to="/EnvironmentAgency" class="text-sm font-semibold leading-6 text-gray-200">Environment
            Agency</router-link>
          <router-link to="/HealthDepartment" class="text-sm font-semibold leading-6 text-gray-200">Health
            Department</router-link>
          <router-link to="/Facilitator" class="text-sm font-semibold leading-6 text-gray-200">Facilitator</router-link>
        </template>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref, computed } from 'vue'

const mobileMenuOpen = ref(false)

const isDevelopment = computed(() => {
  // 如果是开发环境，显示所有角色并可以随意切换，
  // npm run build 之后, 只显示 HomePage 和 About 链接
  return process.env.NODE_ENV === 'development'
})
</script>
