import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import CallbackView from "@/views/CallBackView.vue";


// 定义路由配置
const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue') // 懒加载
  },
  {
    path: '/EnvironmentAgency',
    name: 'EnvironmentAgency',
    component: () => import('@/views/EnvironmentAgency.vue') // 懒加载
  },
  // HealthAgency
  {
    path: '/HealthDepartment',
    name: 'HealthDepartment',
    component: () => import('@/views/HealthDepartment.vue') // 懒加载
  },
  {
    path: '/LeafResident',
    name: 'LeafResident',
    component: () => import('@/views/LeafResident.vue') // 懒加载
  },
  // CorporationManager
  {
    path: '/SynthoChemCorporation',
    name: 'SynthoChemCorporation',
    component: () => import('@/views/SynthoChemCorporation.vue') // 懒加载
  },
  // WaterTreatment
  {
    path: '/WaterTreatmentPlant',
    name: 'WaterTreatmentPlant',
    component: () => import('@/views/WaterTreatmentPlant.vue') // 懒加载
  },
  // Facilitator
  {
    path: '/Facilitator',
    name: 'Facilitator',
    component: () => import('@/views/FacilitatorRole.vue') // 懒加载
  }
      

];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
