import { createApp } from 'vue'
import App from './App.vue'
import router from './router' 
import { createLogto, LogtoConfig } from '@logto/vue';
import { endpoint, appId } from "./consts/logto";
import './assets/tailwind.css'

const config:LogtoConfig = {
    endpoint: endpoint,
    appId: appId,
  };


// 创建Vue应用实例，并传递router
const app = createApp(App)

app.use(createLogto, config);
app.use(router) // 使用router

app.mount('#app') // 挂载应用到DOM
