<!-- PasswordCheck.vue -->
<template>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-xl">
            <h2 class="text-lg font-semibold mb-4">Please Enter Password</h2>
            <input v-model="password" type="password" class="border rounded px-2 py-1 mb-4"
                @keyup.enter="checkPassword">
            <div class="flex justify-end">
                <button @click="checkPassword" class="bg-blue-500 text-white px-4 py-2 rounded">Login</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineProps, defineEmits } from 'vue'

const props = defineProps(['route', 'correctPassword']);
const emit = defineEmits(['close']);

const password = ref('');
const router = useRouter();

const checkPassword = () => {
    if (password.value === props.correctPassword) {
        router.push(props.route);
        emit('close');
    } else {
        alert('Wrong Password');
    }
};
</script>